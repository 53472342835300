import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './../../../Assets/Images/Leyout/header/neireez.webp';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const fetchCategoryProducts = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/categories?type=product`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const Navbar = () => {
    const user = useSelector((state) => state.user);
    const { data : categoryProducts, error, isLoading } = useQuery(['categoryProductsNav'], fetchCategoryProducts);
    
    const location = useLocation();
    const toggleCategoryMobile = () => {
        document.getElementById('sidbar-category-mobile').classList.toggle('active-sidbar-mobile');
        document.getElementById('close-sidbar-category-main').classList.toggle('active-sidbar-close-mobile');
    }

    return (
        <>
            <section className='lg:hidden w-[100vw] h-[4rem] bg-white z-[90] border-t border-[#00000014] fixed bottom-0 right-0 grid grid-cols-4'>
                <Link to={'/shop'} className={`flex justify-center items-center flex-col ${(location.pathname === '/shop') ? 'active-navbar-shop' : ''}`}>
                    {(location.pathname === '/shop') ? (
                        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.3657 0.539279C11.5972 -0.178457 10.4041 -0.178458 9.63553 0.539279L0 9.5379L1.36508 10.9996L3.4991 9.00663V16.0002C3.4991 17.6571 4.84225 19.0002 6.4991 19.0002H15.5013C17.1582 19.0002 18.5013 17.6571 18.5013 16.0002V9.00586L20.6361 10.9996L22.0012 9.5379L12.3657 0.539279Z" fill="#089584"/>
                        </svg>
                        
                    ) : (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3657 2.5383C12.5972 1.82057 11.4041 1.82057 10.6355 2.5383L1 11.5369L2.36508 12.9986L4.4991 11.0057V17.9992C4.4991 19.6561 5.84225 20.9992 7.4991 20.9992H16.5013C18.1582 20.9992 19.5013 19.6561 19.5013 17.9992V11.0049L21.6361 12.9986L23.0012 11.5369L13.3657 2.5383ZM17.5013 9.13709L12.0006 4L6.4991 9.13786V17.9992C6.4991 18.5515 6.94682 18.9992 7.4991 18.9992H16.5013C17.0536 18.9992 17.5013 18.5515 17.5013 17.9992V9.13709Z" fill="#212121"/>
                        </svg>
                    )}

                    <p className='text-[#212121] text-[12px] font-[400] mt-[0.2rem]'>فروشگاه</p>
                </Link>

                <button onClick={toggleCategoryMobile} className='flex justify-center items-center flex-col'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10 2H3C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H10C10.5523 11 11 10.5523 11 10V3C11 2.44772 10.5523 2 10 2ZM4 9V4H9V9H4ZM21 13C21.5523 13 22 13.4477 22 14V21C22 21.5523 21.5523 22 21 22H14C13.4477 22 13 21.5523 13 21V14C13 13.4477 13.4477 13 14 13H21ZM17.5 11C15.0147 11 13 8.98528 13 6.5C13 4.01472 15.0147 2 17.5 2C19.9853 2 22 4.01472 22 6.5C22 8.98528 19.9853 11 17.5 11ZM20 6.5C20 7.88071 18.8807 9 17.5 9C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5ZM6.5 22C4.01472 22 2 19.9853 2 17.5C2 15.0147 4.01472 13 6.5 13C8.98528 13 11 15.0147 11 17.5C11 19.9853 8.98528 22 6.5 22ZM9 17.5C9 18.8807 7.88071 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5ZM15 20V15H20V20H15Z" fill="#212121"/>
                    </svg>

                    <p className='text-[#212121] text-[12px] font-[400] mt-[0.2rem]'>دسته بندی</p>
                </button>

                <Link to={'/my-wishlist'} className='flex justify-center items-center flex-col'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3C4.18629 3 1.5 5.68629 1.5 9C1.5 10.8021 2.30058 12.4754 3.65631 13.6073L10.6381 20.0991C11.4057 20.8129 12.5943 20.8129 13.3619 20.099L20.3547 13.5961C21.6855 12.5041 22.5 10.8176 22.5 9C22.5 5.68629 19.8137 3 16.5 3C14.7393 3 13.116 3.76553 12 5.03031C10.884 3.76553 9.26067 3 7.5 3ZM4.99778 12.1237L4.95388 12.0852C4.03888 11.329 3.5 10.2096 3.5 9C3.5 6.79086 5.29086 5 7.5 5C9.04795 5 10.4358 5.8867 11.1005 7.25491C11.4652 8.00561 12.5348 8.00561 12.8995 7.25491C13.5642 5.8867 14.9521 5 16.5 5C18.7091 5 20.5 6.79086 20.5 9C20.5 10.213 19.958 11.3353 19.0386 12.0914L12 18.6345L4.99778 12.1237Z" fill="#212121"/>
                    </svg>

                    <p className='text-[#212121] text-[12px] font-[400] mt-[0.2rem]'>علاقه مندی ها</p>
                </Link>

                {user?.profile ? (
                    <Link to={'/dashboard'} className='flex justify-center items-center flex-col'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 2C14.7615 2 17.0001 4.23858 17.0001 7V8C17.0001 10.7614 14.7615 13 12.0001 13C9.23866 13 7.00008 10.7614 7.00008 8V7C7.00008 4.23858 9.23866 2 12.0001 2ZM21.9958 20.908C21.5718 16.3177 18.0953 14 12.0001 14C5.90489 14 2.42841 16.3177 2.00432 20.908C1.95017 21.4941 2.41147 22 3.00008 22H21.0001C21.5887 22 22.05 21.4941 21.9958 20.908ZM4.18846 20C4.91629 17.3229 7.41937 16 12.0001 16C16.5808 16 19.0839 17.3229 19.8117 20H4.18846ZM9.00012 7C9.00012 5.34315 10.3433 4 12.0001 4C13.657 4 15.0001 5.34315 15.0001 7V8C15.0001 9.65685 13.657 11 12.0001 11C10.3433 11 9.00012 9.65685 9.00012 8V7Z" fill="#212121"/>
                        </svg>

                        <p className='text-[#212121] text-[12px] font-[400] mt-[0.2rem]'>مشاهده پروفایل</p>
                    </Link>
                ) : (
                    <Link to={'/auth'} className='flex justify-center items-center flex-col'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 2C14.7615 2 17.0001 4.23858 17.0001 7V8C17.0001 10.7614 14.7615 13 12.0001 13C9.23866 13 7.00008 10.7614 7.00008 8V7C7.00008 4.23858 9.23866 2 12.0001 2ZM21.9958 20.908C21.5718 16.3177 18.0953 14 12.0001 14C5.90489 14 2.42841 16.3177 2.00432 20.908C1.95017 21.4941 2.41147 22 3.00008 22H21.0001C21.5887 22 22.05 21.4941 21.9958 20.908ZM4.18846 20C4.91629 17.3229 7.41937 16 12.0001 16C16.5808 16 19.0839 17.3229 19.8117 20H4.18846ZM9.00012 7C9.00012 5.34315 10.3433 4 12.0001 4C13.657 4 15.0001 5.34315 15.0001 7V8C15.0001 9.65685 13.657 11 12.0001 11C10.3433 11 9.00012 9.65685 9.00012 8V7Z" fill="#212121"/>
                        </svg>

                        <p className='text-[#212121] text-[12px] font-[400] mt-[0.2rem]'>ورود | ثبت‌نام</p>
                    </Link>
                )}
            </section>

            <section id='sidbar-category-mobile'>
                <section className='w-full flex flex-col p-[1rem]'>
                    <ul>
                        <li className='flex justify-between items-center pb-[2rem] pt-[1rem] px-[0.5rem] w-full'>
                            <div className='flex justify-center items-center'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10 2H3C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H10C10.5523 11 11 10.5523 11 10V3C11 2.44772 10.5523 2 10 2ZM4 9V4H9V9H4ZM21 13C21.5523 13 22 13.4477 22 14V21C22 21.5523 21.5523 22 21 22H14C13.4477 22 13 21.5523 13 21V14C13 13.4477 13.4477 13 14 13H21ZM17.5 11C15.0147 11 13 8.98528 13 6.5C13 4.01472 15.0147 2 17.5 2C19.9853 2 22 4.01472 22 6.5C22 8.98528 19.9853 11 17.5 11ZM20 6.5C20 7.88071 18.8807 9 17.5 9C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5ZM6.5 22C4.01472 22 2 19.9853 2 17.5C2 15.0147 4.01472 13 6.5 13C8.98528 13 11 15.0147 11 17.5C11 19.9853 8.98528 22 6.5 22ZM9 17.5C9 18.8807 7.88071 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5ZM15 20V15H20V20H15Z" fill="#212121"/>
                                </svg>

                                <p className='text-[#151515] text-18px] font-[700] mr-[0.5rem]'>دسته‌بندی</p>
                            </div>

                            <button onClick={toggleCategoryMobile}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3642 7.05044L16.9499 5.63623L12.0002 10.586L7.05044 5.63623L5.63623 7.05044L10.586 12.0002L5.63623 16.9499L7.05044 18.3642L12.0002 13.4144L16.9499 18.3642L18.3642 16.9499L13.4144 12.0002L18.3642 7.05044Z" fill="#424750"/>
                                </svg>
                            </button>
                        </li>
                        {categoryProducts?.data && Array.isArray(categoryProducts?.data) ? (
                            categoryProducts?.data.map((data,index) => (
                                <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                                    <Link to={`/search?category=${data?.id}`} className='flex justify-between items-center'>
                                        <div>
                                            <p className='text-[#424750] text-[13px] font-[700]'>{data?.title}</p>
                                        </div>

                                        <div>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#424750"/>
                                            </svg>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        ) : (
                            <></>
                        )}
                    </ul>
                </section>
            </section>

            <div id='close-sidbar-category-main' onClick={toggleCategoryMobile}></div>
        </>
    );
};

export default Navbar;